<template>
    <v-row class="justify-center easyItem">
        <v-col v-if="side == 'l'" cols="12" md="4" sm="0"></v-col>
        <v-col cols="12" md="2" sm="2" class="d-flex align-center justify-center">
            <svg width="65" height="65" class="custom-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <defs>
                    <linearGradient id="grad3" x1="0" y1="0" x2="1" y2="1">
                        <stop offset="10%" stop-color="#284731" />
                        <stop offset="42%" stop-color="#43b02a" />
                        <stop offset="52%" stop-color="#2fb08c" />
                        <stop offset="88%" stop-color="#442b53" />
                    </linearGradient>
                </defs>
                <path fill="url(#grad3)" :d="img" />
            </svg>
        </v-col>
        <v-col cols="12" sm="8" :md="side == 'l' ? 5 : 6" :class="{ 'text-left ': $vuetify.breakpoint.smAndUp }">
            <b>{{ title }}</b><br>
            <span>{{ desc }}</span>
        </v-col>
        <v-col v-if="side == 'r'" cols="12" md="3"></v-col>
    </v-row>
</template>
<script>
export default {
    name: 'easyIconComponent',
    props: ['title', 'desc', 'img', 'side']
}
</script>
<style>
.easyItem b, .easyItem span{
    font-family: n27regular;
}
</style>