<template>
    <v-col cols="12" md="4">
        <v-row class="howitworksRow justify-center">
            <v-col cols="12" md=2 sm="2">
                <img :src="img">
            </v-col>
            <v-col cols="12" sm="8" :class="{ 'text-left ml-2': $vuetify.breakpoint.smAndUp }">
                <b>{{ title }}</b><br>
                <span v-html="desc" />
            </v-col>
        </v-row>
    </v-col>
</template>
<script>
export default {
    name: 'how_it_works_item',
    props: ['title', 'desc', 'img']
}
</script>
<style>
.howitworksRow img {
    width: 100%
}

@media(max-width: 768px) {
    .howitworksRow img {
        width: 20% !important
    }
}

@media(max-width: 900px) and (orientation: landscape) {
    .howitworksRow img {
        width: 70% !important
    }
}

@media(min-width:1024px) {
    .howitworksRow {
        height: 150px
    }
}
</style>