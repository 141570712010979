<template>
  <div>
    <div class="hero-banner">
      <v-row align="center" justify="center" class="rowbanner">
        <v-col cols="12" md="5" style="text-align: left;">
          <h2 class="text-white bannerTitle">VENDE TU<br> MOTO AL<br> MEJOR<br> PRECIO</h2>
        </v-col>
        <v-col cols="12" md="7" style="text-align: end;">
        </v-col>
      </v-row>
    </div>
    <main id="ts-content">
      <section class=" text-center howsection pl-5  pr-5">
        <div class="ts-title">
          <h2 class="howTitle mb-15">CÓMO FUNCIONA</h2>
        </div>
        <v-row align="center" justify="center">
          <how_it_works_item img="assets/img/howitworks1.png" title="Sube tu moto"
            :desc="'Sin necesidad de registro previo.<br>Responde unas breves preguntas y sube fotos. <br>¡Fácil y rápido!'" />
          <how_it_works_item img="assets/img/howitworks2.png" title="Búsqueda de ofertas"
            :desc="'De concesionarios por todo el país. Seleccionamos la mejor oferta de todas para tí'" />
          <how_it_works_item img="assets/img/howitworks3.png" title="¡Vendida!"
            :desc="'Pago seguro del 100% del importe en tu cuenta.<br>Recogida a domicilio y cambio de nombre incluidos<br>¡Nos encargamos de todo por tí!'" />
        </v-row>
      </section>
      <section id="features" class="ts-block mt-5">
        <v-row style="padding: 1% 6%; margin:0 !important">
          <v-col cols="12" md="5" sm="5" class="rules" style="display: flex; flex-direction: column; justify-content: center;">
            <h2>LAS REGLAS<br> DEL JUEGO<br> HAN CAMBIADO</h2>
              <p  :class="{ 'mt-15': $vuetify.breakpoint.mdAndUp }">En Bidbike tú serás el vendedor y cientos de concesionarios de toda España serán tus
                compradores.</p>
              <p class="mt-5">Interesante, ¿verdad?</p>
          </v-col>
          <v-col cols=12 md="6" sm="7" style="display: flex; flex-direction: column; justify-content: center;">
            <div class="image position-relative">
                <img src="../assets/reglasimg.png" width="100%">
              </div>
          </v-col>
        </v-row>
        
        
      </section>
      <section class="ts-block text-center easysection pl-5 pr-5">
        <div class="ts-title">
          <h2 class="howTitle mb-15">¡MÁS FÁCIL IMPOSIBLE!</h2>
        </div>
        <v-row align="center" justify="center">
          <v-col cols="12" md="6" justify="center">
            <easyIconComponent side="l" title="Gratis" desc="Vender tu moto en Bidbike no tiene coste"
              img="M5,6H23V18H5V6M14,9A3,3 0 0,1 17,12A3,3 0 0,1 14,15A3,3 0 0,1 11,12A3,3 0 0,1 14,9M9,8A2,2 0 0,1 7,10V14A2,2 0 0,1 9,16H19A2,2 0 0,1 21,14V10A2,2 0 0,1 19,8H9M1,10H3V20H19V22H1V10Z" />
            <easyIconComponent side="l" title="Todo incluido"
              desc="Olvídate de gastos y gestiones. Nosotros nos encargamos"
              img="M18,11H14.82C14.4,9.84 13.3,9 12,9C10.7,9 9.6,9.84 9.18,11H6C5.67,11 4,10.9 4,9V8C4,6.17 5.54,6 6,6H16.18C16.6,7.16 17.7,8 19,8A3,3 0 0,0 22,5A3,3 0 0,0 19,2C17.7,2 16.6,2.84 16.18,4H6C4.39,4 2,5.06 2,8V9C2,11.94 4.39,13 6,13H9.18C9.6,14.16 10.7,15 12,15C13.3,15 14.4,14.16 14.82,13H18C18.33,13 20,13.1 20,15V16C20,17.83 18.46,18 18,18H7.82C7.4,16.84 6.3,16 5,16A3,3 0 0,0 2,19A3,3 0 0,0 5,22C6.3,22 7.4,21.16 7.82,20H18C19.61,20 22,18.93 22,16V15C22,12.07 19.61,11 18,11M19,4A1,1 0 0,1 20,5A1,1 0 0,1 19,6A1,1 0 0,1 18,5A1,1 0 0,1 19,4M5,20A1,1 0 0,1 4,19A1,1 0 0,1 5,18A1,1 0 0,1 6,19A1,1 0 0,1 5,20Z" />
            <easyIconComponent side="l" title="Rapidez y comodidad"
              desc="Venta garantizada en tiempo récord con recogida a domicilio"
              img="M16 1.2C15 1.2 14.2 2 14.2 3S15 4.8 16 4.8 17.8 4 17.8 3 17 1.2 16 1.2M12.4 4.1C11.93 4.1 11.5 4.29 11.2 4.6L7.5 8.29C7.19 8.6 7 9 7 9.5C7 10.13 7.33 10.66 7.85 10.97L11.2 13V18H13V11.5L10.75 9.85L13.07 7.5L14.8 10H19V8.2H15.8L13.86 4.93C13.57 4.43 13 4.1 12.4 4.1M10 3H3C2.45 3 2 2.55 2 2S2.45 1 3 1H12.79C12.58 1.34 12.41 1.71 12.32 2.11C11.46 2.13 10.65 2.45 10 3M5 12C2.24 12 0 14.24 0 17S2.24 22 5 22 10 19.76 10 17 7.76 12 5 12M5 20.5C3.07 20.5 1.5 18.93 1.5 17S3.07 13.5 5 13.5 8.5 15.07 8.5 17 6.93 20.5 5 20.5M19 12C16.24 12 14 14.24 14 17S16.24 22 19 22 24 19.76 24 17 21.76 12 19 12M19 20.5C17.07 20.5 15.5 18.93 15.5 17S17.07 13.5 19 13.5 22.5 15.07 22.5 17 20.93 20.5 19 20.5M5.32 11H1C.448 11 0 10.55 0 10S.448 9 1 9H5.05C5.03 9.16 5 9.33 5 9.5C5 10.03 5.12 10.54 5.32 11M6 7H2C1.45 7 1 6.55 1 6S1.45 5 2 5H7.97L6.09 6.87C6.05 6.91 6 6.96 6 7Z" />
          </v-col>
          <v-col cols="12" md="6" justify="center">
            <easyIconComponent side="r" title="Mejor precio profesional"
              desc="Pago seguro del 100% de la moto por Transferencia Inmediata antes de entregarla"
              img="M15.5 15.5C16.33 15.5 17 14.83 17 14C17 13.17 16.33 12.5 15.5 12.5C14.67 12.5 14 13.17 14 14C14 14.83 14.67 15.5 15.5 15.5M7 3H17C18.11 3 19 3.9 19 5V7C20.11 7 21 7.9 21 9V19C21 20.11 20.11 21 19 21H7C4.79 21 3 19.21 3 17V7C3 4.79 4.79 3 7 3M17 7V5H7C5.9 5 5 5.9 5 7V7.54C5.59 7.2 6.27 7 7 7H17M5 17C5 18.11 5.9 19 7 19H19V9H7C5.9 9 5 9.9 5 11V17Z" />
            <easyIconComponent side="r" title="Venta 100% digital"
              desc="Con tu ordenador o smartphone desde donde quieras"
              img="M17,19V5H7V19H17M17,1A2,2 0 0,1 19,3V21A2,2 0 0,1 17,23H7C5.89,23 5,22.1 5,21V3C5,1.89 5.89,1 7,1H17M9,7H15V9H9V7M9,11H13V13H9V11Z" />
            <easyIconComponent side="r" title="Anonimato" desc="Tus datos personales protegidos en todo momento"
              img="M17.06 13C15.2 13 13.64 14.33 13.24 16.1C12.29 15.69 11.42 15.8 10.76 16.09C10.35 14.31 8.79 13 6.94 13C4.77 13 3 14.79 3 17C3 19.21 4.77 21 6.94 21C9 21 10.68 19.38 10.84 17.32C11.18 17.08 12.07 16.63 13.16 17.34C13.34 19.39 15 21 17.06 21C19.23 21 21 19.21 21 17C21 14.79 19.23 13 17.06 13M6.94 19.86C5.38 19.86 4.13 18.58 4.13 17S5.39 14.14 6.94 14.14C8.5 14.14 9.75 15.42 9.75 17S8.5 19.86 6.94 19.86M17.06 19.86C15.5 19.86 14.25 18.58 14.25 17S15.5 14.14 17.06 14.14C18.62 14.14 19.88 15.42 19.88 17S18.61 19.86 17.06 19.86M22 10.5H2V12H22V10.5M15.53 2.63C15.31 2.14 14.75 1.88 14.22 2.05L12 2.79L9.77 2.05L9.72 2.04C9.19 1.89 8.63 2.17 8.43 2.68L6 9H18L15.56 2.68L15.53 2.63Z" />
          </v-col>
        </v-row>
      </section>


      <section class="ts-block text-center faqssection pl-5 pr-5">
        <div class="ts-title">
          <h2 class="howTitle mb-15">PREGUNTAS FRECUENTES</h2>
        </div>
        <v-expansion-panels itemscope itemtype="https://schema.org/FAQPage">
          <faqItemVue title="¿Cómo puedo vender mi moto en Bidbike?" response='Símplemente pulsa el botón superior "Vende tu moto" y en un par de minutos tendremos todo lo necesario
                para trabajar con ella' />
          <faqItemVue title="¿Quién es el comprador?"
            response='El comprador será el concesionario o profesional que haga la mejor oferta por tu moto' />
          <faqItemVue title="¿Cuánto cuesta vender mi moto con Bidbike?" response='¡Nada! Siempre recibirás el 100% de la oferta que hayas recibido y los gastos de la venta los pagará tu
                concesionario' />
          <faqItemVue title="¿Cómo funciona el pago seguro?" response='Realizamos el pago mediante Transferencia Inmediata en tu cuenta en el mismo momento que nos encontramos
                contigo y con tu moto, de modo que puedas comprobar in situ que el pago ha llegado correctamente antes de
                que nos vayamos con tu moto. ¡Nunca estarás sin dinero y sin moto al mismo tiempo!' />
          <faqItemVue title="¿Cómo funciona la recogida a domicilio?" response='Recogeremos la moto en la dirección que tú nos indiques. Antes de realizar la recogida coordinaremos
                contigo la fecha y hora de recogida con suficiente antelación' />
          <faqItemVue title="¿Estoy obligado a vender mi moto en Bidbike?"
            response='Subir tu moto a Bidbike no implica compromiso alguno' />
          <faqItemVue title="¿Puedo rechazar la oferta si no me interesa?"
            response='Por supuesto. ¡Tu palabra es siempre la última!' />
          <faqItemVue title="¿Cómo funciona el cambio de nombre?"
            response='El cambio de nombre se realiza en el momento que has recibido el pago de tu moto. Es telemático y suele
                tardar uno o dos días laborables. Recibirás el comprobante del trámite realizado por e-mail o WhatsApp' />
          <faqItemVue title="¿Cuánto tardáis en conseguir la mejor oferta?" response='De uno a dos días laborables' />
          <faqItemVue title="He recibido un correo indicándome que ya puedo ver mi oferta. ¿Dónde la veo?"
            response='Accede a tu cuenta y entra en la ficha de tu moto. Allí podrás ver y aceptar o rechazar nuestra oferta' />
          <faqItemVue title="Vale, he aceptado la oferta recibida. ¿Qué pasa ahora?" response='Simplemente sigue los pasos a través de la web tras aceptarla. Necesitaremos una serie de documentos y
                datos por tu parte que podrás subir en formato PDF o imagen. Nosotros nos encargamos del resto. ¡Es muy
                sencillo!' />
        </v-expansion-panels>
      </section>
    </main>
  </div>
</template>

<script>
// eslint-disable-next-line
import OneSignalVue from 'onesignal-vue';
import how_it_works_item from '@/components/home/howItWorksName.vue';
import easyIconComponent from '@/components/home/easyIconComponent.vue';
import faqItemVue from '@/components/home/faqItem.vue';
export default {
  metaInfo: {
    title: 'Bidbike - Vende tu moto a profesionales de toda España',
  },
  name: 'Home_view',
  components: {
    how_it_works_item,
    easyIconComponent,
    faqItemVue
  },
  beforeCreate() {
    this.$OneSignal.showSlidedownPrompt()
  }


}
</script>
<style>
.rowbanner {
  height: 45rem;
  width: 100%;
  padding: 100px 0px 0px 100px;
}

.filterTitle {
  background: #04d1db
}

.bannerTitle {
  font-size: 55pt;
  font-family: n27regular;
}


section {
  margin-top: 60px;
  margin-bottom: 60px;
}

.easysection b {
  font-size: 18pt;
  font-family: n27regular
}

.easysection span {
  font-size: 14pt;
  font-family: n27regular;
}

.howTitle {
  font-family: n27regular;
  font-size: 40pt;
  color: #43b02a
}

.howsection p,
.howsection span {
  font-family: n27regular
}

#features p {
  width: 60%
}

.rules h2 {
  font-family: n27regular;
  font-size: 40pt;
  color: white
}

.rules p {
  color: white;
  font-size: 18pt;
}


#features {
  background-image: linear-gradient(27deg, #284731 10%, #43b02a 42%, #2fb08c 52%, #442b53 88%);
  ;
}

.easysection {
  margin-bottom: 120px
}

h1,
h2,
.text-title,
.feat-title,
.work-figure h4 {
  font-family: n27bold;
}

.text-title {
  font-size: 16pt;
}

h4,
.text-subtitle,
.feature p,
.work-figure p,
.gamerules,
.faq-response {
  font-family: n27regular;
}

.v-list-item__title,
.v-list-item__subtitle {
  word-wrap: break-word;
}

.v-list--three-line .v-list-item .v-list-item__subtitle,
.v-list-item--three-line .v-list-item__subtitle {
  -webkit-line-clamp: 5;
}

.v-expansion-panel:not(:first-child)::after{
  border-top:none !important;
}
@media(max-width:900px)and (orientation: landscape) {
  .rowbanner {
    padding: 18% 50% 0% 10%;
    display: flex;
    align-items: center;
  }

  .howTitle{
    font-size: 26pt;
  }
  #features h2{
    font-size: 26pt
  }
  #features p {
  width: 100%
}
}

@media(max-width:900px) and (orientation: portrait) {
  .rowbanner {
    padding: 0% 40% 80% 5%;

  }

  .bannerTitle {
    font-size: 20pt;
  }
  .howTitle{
    font-size: 26pt;
  }
  #features h2{
    font-size: 26pt
  }
  #features p {
  width: 100%
}
  .rules, .rules p{
    text-align: center;
  }
}

@media(max-width:900px)and (orientation: landscape) {
  .howsection img {
    width: 100%;
    margin: 0 auto;
  }


}

@media(max-width:900px) and (orientation: portrait) {


  #features {
    padding: 120px 0;
  }

  .howsection img {
    width: 30%;
    margin: 0 auto;
  }

  .howsection span {
    text-align: center !important;
  }

  .easysection span {
    text-align: center;
  }
}
</style>
