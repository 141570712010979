<template>
    <v-expansion-panel itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
        <v-expansion-panel-header itemprop="name">
            {{ title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
            <p itemprop="text" class="faq-response pa-2 text-left">
                {{ response }}
            </p>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
export default {
    name: 'faqItem',
    props: ['title', 'response']
}
</script>
<style>
.v-expansion-panel {
    border-radius: 25px !important;
    border-image: linear-gradient(45deg, #284731 10%, #43b02a 29%, #3bc9db 57%, #4484a0 76%, #442b53 93%);
    border: 3px solid;
    margin-bottom: 5px;
    

}

.v-expansion-panel-header {
    border-radius: 25px !important;
    background: #fff;
    color: black;
    font-family: n27regular;
    font-size: 14pt;
    font-weight: 700;
}

.v-expansion-panel.v-expansion-panel--active.v-item--active .v-expansion-panel-header {
    border-radius: 20px 20px 0 0  !important;
    background: linear-gradient(45deg, #284731 10%, #43b02a 29%, #3bc9db 57%, #4484a0 76%, #442b53 93%);
    color:white
}

.v-expansion-panel-content__wrap {
    padding: 0 10px 0px;
    font-family: n27regular;
    font-size: 14pt;
}
.v-expansion-panel-header--active .mdi-chevron-down{
    color:white !important
}
@media(max-width:768px){
    .v-expansion-panel {
    margin-left: 10px;
    margin-right:10px;
    }
}
</style>